const LOGIN = '/login';
const HOME = '/';
const ABOUT = '/about-us';
const REGISTER = '/register';
const PROFILE = (slug: string): string => {
  return `/celebrity/${slug}`;
};
const BOOKING = (slug: string, shoutOutType: string): string => {
  return `/booking/${slug}/${shoutOutType}`;
};
const TERMS_AND_CONDITIONS = '/terms-conditions';
const PRIVACY_POLICY = '/privacy-policy';
const COOKIE_POLICY = '/cookiepolicy';
const FORGOT_PASSWORD = '/forgot-password';
const ERROR_404 = '/error-404';
const SOCIAL_FACEBOOK_AUTH = '/social/facebook/auth';
export default {
  LOGIN,
  HOME,
  ABOUT,
  REGISTER,
  PROFILE,
  BOOKING,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  FORGOT_PASSWORD,
  ERROR_404,
  SOCIAL_FACEBOOK_AUTH,
};
